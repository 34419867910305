import React, { useState } from 'react';
import './App.css';

function FAQSection() {
  const [showAnswer, setShowAnswer] = useState([false, false, false, false, false]); // Updated length to match the number of questions

  const toggleAnswer = (index) => {
    const newShowAnswer = [...showAnswer];
    newShowAnswer[index] = !newShowAnswer[index];
    setShowAnswer(newShowAnswer);
  };

  return (
<section className="faq">
  <h2 className="section-title">Frequently Asked Questions (FAQ)</h2>

  {/* Question 1 */}
  <div className="faq-item" onClick={() => toggleAnswer(0)}>
    <div className="faq-question">
      <h3>What types of cooperation do you offer?</h3>
      <button className="toggle-button">{showAnswer[0] ? '-' : '+'}</button>
    </div>
    {showAnswer[0] && (
      <p className="faq-answer">We offer both classic models — fixed payment for setting up and managing ad campaigns, and models like CPA, Revenue Share, CPL, and others, where our payment depends on achieved results. You can choose a suitable format based on your goals and budget.</p>
    )}
  </div>

  {/* Question 2 */}
  <div className="faq-item" onClick={() => toggleAnswer(1)}>
    <div className="faq-question">
      <h3>What channels and platforms do you use for advertising?</h3>
      <button className="toggle-button">{showAnswer[1] ? '-' : '+'}</button>
    </div>
    {showAnswer[1] && (
      <p className="faq-answer">We work with major top traffic sources — Google Ads, Facebook, TikTok. With our extensive expertise in these platforms, we can achieve fast and high-quality results.</p>
    )}
  </div>

  {/* Question 3 */}
  <div className="faq-item" onClick={() => toggleAnswer(2)}>
    <div className="faq-question">
      <h3>What verticals do you cover in your work?</h3>
      <button className="toggle-button">{showAnswer[2] ? '-' : '+'}</button>
    </div>
    {showAnswer[2] && (
      <p className="faq-answer">We work with a wide range of verticals, including e-commerce, health and beauty, iGaming, and more. We tailor optimal advertising strategies for each niche.</p>
    )}
  </div>

  {/* Question 4 */}
  <div className="faq-item" onClick={() => toggleAnswer(3)}>
    <div className="faq-question">
      <h3>How do you develop strategies for ad campaigns?</h3>
      <button className="toggle-button">{showAnswer[3] ? '-' : '+'}</button>
    </div>
    {showAnswer[3] && (
      <p className="faq-answer">We conduct in-depth analysis of the target audience, competitive environment, and market trends to develop unique strategies that help you achieve your business goals.</p>
    )}
  </div>

  {/* Question 5 */}
  <div className="faq-item" onClick={() => toggleAnswer(4)}>
    <div className="faq-question">
      <h3>How long does it take to set up and launch a campaign?</h3>
      <button className="toggle-button">{showAnswer[4] ? '-' : '+'}</button>
    </div>
    {showAnswer[4] && (
      <p className="faq-answer">The time needed to set up and launch a campaign depends on the complexity of the project, but it usually takes 5 to 10 business days, including the stages of analysis, creative preparation, and testing.</p>
    )}
  </div>
</section>

  );
}

export default FAQSection;
