import React from 'react';
import './App.css';

function Benefits() {
  return (
    <section className="benefits">
      <h2 className="section-title">Benefits of Working with Us</h2>
      <ul className="benefits-list">
        <li className="benefit-item">
          <div className="benefit-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24px" height="24px">
              <path d="M12 2L2 22h20L12 2z"></path>
              <line x1="12" y1="9" x2="12" y2="13"></line>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
          </div>
          Deep Expertise in Traffic Management
        </li>
        <li className="benefit-item">
          <div className="benefit-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24px" height="24px">
              <circle cx="12" cy="12" r="3"></circle>
              <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1-2.83 2.83l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51v.28a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-.28a1.65 1.65 0 0 0-1-1.51 1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 1 1-2.83-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2v-1a2 2 0 0 1 2-2h.28a1.65 1.65 0 0 0 1.51-1 1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33h.28a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v.28a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 1 1 2.83 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82v.28a1.65 1.65 0 0 0 1.51 1h.28a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-.28a1.65 1.65 0 0 0-1.51 1z"></path>
            </svg>
          </div>
          Focused on Your Success, Not Templates
        </li>
        <li className="benefit-item">
          <div className="benefit-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24px" height="24px">
              <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
              <line x1="3" y1="9" x2="21" y2="9"></line>
              <line x1="9" y1="21" x2="9" y2="9"></line>
            </svg>
          </div>
          A Team of Specialists Focused on Results
        </li>
        <li className="benefit-item">
          <div className="benefit-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="24px" height="24px">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="8" y1="12" x2="12" y2="16"></line>
              <line x1="12" y1="16" x2="16" y2="8"></line>
            </svg>
          </div>
          Delivering Results That Meet Expectations
        </li>
      </ul>
    </section>
  );
}

export default Benefits;
