import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import './App.css'; // Ensure the styles are connected

function ContactForm() {
  const [showNotification, setShowNotification] = useState(false); // State to manage the notification

  const sendEmail = (e) => {
    e.preventDefault(); // Prevent page reload

    emailjs.sendForm('service_ses3qni', 'template_ix16qjq', e.target, 'mFiHknyFxsP2FFrgS') // Use new Public Key
      .then((result) => {
        console.log(result.text);
        setShowNotification(true); // Show notification
        setTimeout(() => setShowNotification(false), 2000); // Hide notification after 2 seconds
        e.target.reset(); // Clear the form after submission
      }, (error) => {
        console.log(error.text);
        alert('An error occurred while sending the message.');
      });
  };

  return (
    <section className="contact-form">
      <h2 className="section-title">Contact Us</h2>
      <form className="form" onSubmit={sendEmail}>
        <input type="text" name="name" placeholder="Your Name" required className="form-input" />
        <input type="email" name="email" placeholder="Your Email" required className="form-input" />
        <textarea name="message" placeholder="Your Message" required className="form-textarea"></textarea>
        <button type="submit" className="form-button">Send</button>
      </form>

      {/* Custom notification */}
      {showNotification && (
        <div className={`notification show`}>
          Message sent successfully!
        </div>
      )}
    </section>
  );
}

export default ContactForm;
