import React, { useState, useEffect } from 'react'; // Импортируем useState и useEffect
import './App.css'; // Импорт стилей
import Benefits from './Benefits'; // Импорт компонента Преимущества
import FAQSection from './FAQSection'; // Импорт компонента FAQ
import ContactForm from './ContactForm'; // Импорт компонента Форма обратной связи

function App() {
  // Состояния для управления раскрытием услуг
  const [showDetails, setShowDetails] = useState([false, false, false]);

  // Функция для переключения состояния
  const toggleDetails = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  // Добавляем useEffect для управления видео
  useEffect(() => {
    const video = document.querySelector('.background-video');
    if (video) {
      // Отключение взаимодействия с видео на мобильных устройствах
      video.addEventListener('click', function (e) {
        e.preventDefault(); // Запретить нажатие, чтобы не открывался плеер
      });

      // Принудительное воспроизведение видео
      video.play().catch((error) => {
        console.error("Ошибка при воспроизведении видео:", error);
        video.load(); // Загружаем видео заново при ошибке
        video.play();
      });
    }
  }, []); // Пустой массив зависимостей, чтобы эффект выполнился только один раз при загрузке компонента

  return (
    <div className="App">
      <video autoPlay loop muted playsInline preload="auto" className="background-video">
        <source src="/background-video.mp4" type="video/mp4" />
        Ваш браузер не поддерживает HTML5 видео.
      </video>



      <header className="header">
        <img src="/logo.png" alt="Advector Logo" className="logo" />
        <h1 className="header-title">AdRealTime</h1>
        <p className="header-subtitle">Driving high-quality traffic for sustainable business growth</p>
      </header>

      <section className="about">
        <h2 className="section-title">About Us</h2>
        <p className="section-text">
          AdRealTime is a team of recognized media buying experts with 5 years of experience across various verticals. We develop personalized strategies that ensure a consistent flow of high-quality traffic and outstanding results for our partners. We know how to achieve success in the most competitive niches and help your projects grow.
        </p>
      </section>
      
      <section className="services">
        <h2 className="section-title">Our Services</h2>
        <div className="service-list">
          {/* Услуга 1 */}
          <div className="service-item">
            <div className="service-header" onClick={() => toggleDetails(0)}>
              <h3>Lead Generation</h3>
              <button className="toggle-button">{showDetails[0] ? '-' : '+'}</button>
            </div>
            {showDetails[0] && (
              <p>The full cycle of customer acquisition — from audience research and developing unique offers to launching ad campaigns and tracking conversions.</p>
            )}
          </div>
          {/* Услуга 2 */}
          <div className="service-item">
            <div className="service-header" onClick={() => toggleDetails(1)}>
              <h3>Creative Development and Branding</h3>
              <button className="toggle-button">{showDetails[1] ? '-' : '+'}</button>
            </div>
            {showDetails[1] && (
              <p>Creating compelling creatives and advertising materials that capture the audience's attention and enhance brand recognition. Working on visual identity and unique selling propositions.</p>
            )}
          </div>
          {/* Услуга 3 */}
          <div className="service-item">
            <div className="service-header" onClick={() => toggleDetails(2)}>
              <h3>Multichannel Strategy Management</h3>
              <button className="toggle-button">{showDetails[2] ? '-' : '+'}</button>
            </div>
            {showDetails[2] && (
              <p>Development and implementation of comprehensive strategies for various advertising platforms and channels (Facebook, Google, TikTok, etc.), optimizing budgets and targeting for maximum reach and engagement.</p>
            )}
          </div>
        </div>
      </section>

      {/* Секция Преимущества */}
      <Benefits />

      {/* Секция FAQ */}
      <FAQSection />

      <ContactForm />  {/* Форма обратной связи */}
      
 <footer
  style={{
    backgroundColor: '#1F1F1F',
    padding: '40px 20px',
    color: '#CCCCCC',
    marginTop: '40px',
  }}
>
  {/* Авторские права */}
  <div
    style={{
      textAlign: 'center',
      marginBottom: '20px',
    }}
  >
    <p style={{ margin: 0, fontSize: '14px' }}>© 2024 AdRealTime. All rights reserved.</p>
  </div>

  {/* Контейнер для контактов и адреса */}
  <div
    style={{
      display: 'flex',
      justifyContent: 'center', // Центрирование колонок
      alignItems: 'flex-start',
      flexWrap: 'wrap', // Адаптивность для мобильных устройств
      gap: '20px', // Уменьшенный промежуток между колонками
      margin: '0 auto', // Центрирование контейнера
      maxWidth: '800px',
    }}
  >
    {/* Контакты */}
    <div style={{ flex: '0 1 auto', width: '300px' }}>
      <h4
        style={{
          fontSize: '16px',
          color: '#FFFFFF',
          marginBottom: '10px',
          textTransform: 'uppercase',
          textAlign: 'center', // Центрирование заголовка
        }}
      >
        Contacts
      </h4>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center', // Центрирование иконок
          gap: '10px',
          marginBottom: '10px',
        }}
      >
        <a
          href="https://wa.me/447576507733"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'inline-block' }}
        >
          <img
            src="/whatsapp-icon.png"
            alt="WhatsApp"
            style={{
              width: '24px',
              height: '24px',
              filter: 'invert(100%)',
            }}
          />
        </a>
        <a
          href="https://t.me/buggatiki"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'inline-block' }}
        >
          <img
            src="/telegram-icon.png"
            alt="Telegram"
            style={{
              width: '24px',
              height: '24px',
              filter: 'invert(100%)',
            }}
          />
        </a>
      </div>
      <a
        href="mailto:info@adrealtime.pro"
        style={{
          color: '#FFD700',
          textDecoration: 'none',
          display: 'block',
          marginTop: '10px',
          textAlign: 'center', // Центрирование email
        }}
      >
        info@adrealtime.pro
      </a>
    </div>

    {/* Адрес */}
    <div style={{ flex: '0 1 auto', width: '300px' }}>
      <h4
        style={{
          fontSize: '16px',
          color: '#FFFFFF',
          marginBottom: '10px',
          textTransform: 'uppercase',
          textAlign: 'center', // Центрирование заголовка
        }}
      >
        Address
      </h4>
      <p style={{ margin: 0, lineHeight: '1.6', textAlign: 'center' }}>28 Windsor Avenue</p>
      <p style={{ margin: 0, lineHeight: '1.6', textAlign: 'center' }}>London</p>
      <p style={{ margin: 0, lineHeight: '1.6', textAlign: 'center' }}>HA8 8SR</p>
      <p style={{ margin: 0, lineHeight: '1.6', textAlign: 'center' }}>United Kingdom</p>
    </div>
  </div>
</footer>




    </div>
  );
}

export default App;
